import React from "react"
import Logo from "../../images/export-catalyst-logo.svg"

const Footer = () => (
  <footer className="footer">
    <div className="wrapper">
      <div className="footer_inner">
        <img src={Logo} alt="footer logo" />
        <div>
          <ul>
            <li>
              <a
                href="https://www.gov.uk/help/terms-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://healthcareuk.blog.gov.uk/"
                target="_blank"
                rel="noreferrer"
              >
                All GOV.UK blog posts
              </a>
            </li>
            <li>
              <a href="https://www.gov.uk/" target="_blank" rel="noreferrer">
                GOV.UK
              </a>
            </li>
            <li>
              <a
                href="https://www.gov.uk/help/cookies"
                target="_blank"
                rel="noreferrer"
              >
                Cookies
              </a>
            </li>
          </ul>
          <p>
            2021 Healthcare UK ·{" "}
            <a href="https://studiomade.co/" target="_blank" rel="noreferrer">
              Studiomade
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
