export const HOME = "/"
export const SIGN_UP = "/signup"
export const SIGN_IN = "/signin"
export const QUESTIONNAIRE = "/questionnaire"
export const RESULTS = "/results"
export const ACCOUNT = "/account"
export const PASSWORD_FORGET = "/pw-forget"
export const PASSWORD_RESET = "/reset-password"
export const ADMIN = "/admin"
export const ADMIN_DETAILS = "/admin/:id"
