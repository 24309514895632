import firebase from "gatsby-plugin-firebase"

class Firebase {
  constructor(app) {
    /* Helper */

    this.serverValue = firebase.database.ServerValue
    this.emailAuthProvider = firebase.auth.EmailAuthProvider

    /* Firebase APIs */

    this.auth = firebase.auth()
    this.db = firebase.database()
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "http://localhost:8000",
    })

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then(snapshot => {
            const dbUser = snapshot.val()

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {}
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            }

            next(authUser)
          })
      } else {
        fallback()
      }
    })

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`)

  users = () => this.db.ref("users")

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`)

  messages = () => this.db.ref("messages")

  // *** Form submissions ***
  submission = uid => this.db.ref(`form_submissions`)
}

let firebaseConnect

function getFirebase(app, auth, database) {
  if (!firebaseConnect) {
    firebaseConnect = new Firebase(app, auth, database)
  }

  return firebaseConnect
}

export default getFirebase
