import React, { Component } from "react"
import Navigation from "../Navigation"
import Logo from "../../images/export-catalyst-logo.svg"
import LogoWhite from "../../images/export-catalyst-logo--white.svg"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  openMenu = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <header className={this.state.open ? "header open" : "header"}>
        <div className="wrapper">
          <div
            className={this.props.dark ? "header_inner dark" : "header_inner"}
          >
            <div className="logo"></div>

            <button className="menu_btn" onClick={this.openMenu}>
              <span></span>
              <span></span>
              <span></span>
            </button>

            <Navigation activePage={this.props.activePage} />
          </div>
        </div>
      </header>
    )
  }
}

export default Header
