import React, { Component, Fragment } from "react"
import Header from "./Header"
import Footer from "./Footer"
import getFirebase, { FirebaseContext } from "./Firebase"
import withAuthentication from "./Session/withAuthentication"

class Layout extends Component {
  state = {
    firebase: null,
  }

  componentDidMount() {
    const app = import("firebase/app")
    const auth = import("firebase/auth")
    const database = import("firebase/database")

    Promise.all([app, auth, database]).then(values => {
      const firebase = getFirebase(values[0])

      this.setState({ firebase })
    })
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    )
  }
}

const AppWithAuthentication = withAuthentication(
  ({ children, activePage, dark }) => (
    <Fragment>
      <div className={dark ? "site-wrapper dark" : "site-wrapper"}>
        <Header activePage={activePage} dark={dark} />
        <main>{children}</main>
        <Footer />
      </div>
    </Fragment>
  )
)

export default Layout
